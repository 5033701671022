<template>
  <div class="d-flex mb-3">
    <data-loader :custom-loading-trigger="isLoading" class="mr-4"></data-loader>
    <h3 v-if="!isLoading">{{ title }}</h3>
    <h3 v-else>{{ $t('global.loading-data') }}</h3>
  </div>
</template>
<script>
import DataLoader from "@/components/common/DataLoader.vue";

export default {
  components: {DataLoader},
  props: {
    title: { type: String, required: false, default: '' },
    isLoading: {type: Boolean, required: false, default: true}
  },
}
</script>
