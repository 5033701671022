import {filterManagement} from "@/services/filters"

export default {
  data() {
    return {
      search: filterManagement.getSearchFromUrlQuery(this.$route),
    }
  },
  mounted() {
    this.debounceGetDataFromApi()
    window.addEventListener("popstate", this.handleSearchFilterOnNavigatingBack);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handleSearchFilterOnNavigatingBack);
  },
  methods: {
    handleSearchChanged() {
      filterManagement.resetPagination(this.options)
      filterManagement.setSearchFilterInUrlQuery(this.search)
      this.debounceGetDataFromApi()
    },
    handleSearchFilterOnNavigatingBack(event) {
      if (event?.type === 'popstate') {
        this.search = filterManagement.getSearchFromUrlQuery(this.$route)
        this.debounceGetDataFromApi()
      }
    },
  }
}
