<template>
  <div>
    <PageTitle
      :title="$t('listing.employers.title')"
      icon="mdi-account-group"
    ></PageTitle>
    <EmployersList
      @add-employer="createEmployer"
      @show-employer-details="employerDetails"
    />
  </div>
</template>
<script>
import EmployersList from '@/components/employers/EmployersList.vue'
import PageTitle from '@/components/layout/PageTitle.vue'
import RouterMixin from '@/mixins/routerMixin'

export default {
  components: { PageTitle, EmployersList },
  mixins: [RouterMixin],
  methods: {
    createEmployer() {
      this.routerPush('/employers/details')
    },
    employerDetails(employerId) {
      this.routerPush(`/employers/${employerId}/details`)
    }
  }
}
</script>
