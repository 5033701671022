
import debounce from 'lodash.debounce'
import {filterManagement} from "@/services/filters"
import validItemsPerPage from '@/helpers/constants'

export default {
  data() {
    return {
      items: [],
      debounceGetDataFromApi: debounce(this.getDataFromApi, 500),
      options: filterManagement.getTableOptionsFromUrlQuery(this.$route),
      loading: true,
      totalItems: 0,
      itemsPerPageOptions: validItemsPerPage,
      isNavigatingBack: false,
    }
  },
  methods: {
    handleNavigatedBack(event) {
      if (event?.type === 'popstate') {
        this.isNavigatingBack = true
        this.options = filterManagement.getTableOptionsFromUrlQuery(this.$route)
        this.debounceGetDataFromApi()
      }
    },

    handleTableOptionUpdated(options) {
      if (!this.isNavigatingBack)
        filterManagement.setTableOptionsInUrlQuery(options)

      this.debounceGetDataFromApi()
      this.isNavigatingBack = false
    },
  },
  mounted() {
    window.addEventListener("popstate", this.handleNavigatedBack);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handleNavigatedBack);
  },
}

