<template>
  <v-card class="pa-8">
    <v-row no-gutters>
      <v-col>
        <DataListTitle
          :title="`${totalItems} ${$t('listing.employers.registered')}`"
          :is-loading="loading"
        ></DataListTitle>
      </v-col>
    </v-row>
    <div>
      <v-row no-gutters class="mb-5">
        <v-col cols="4">
          <v-text-field
            v-model="search"
            data-cy="input-search"
            append-icon="mdi-magnify"
            :label="$t('listing.employers.search')"
            single-line
            hide-details
            @input="handleSearchChanged"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="{
          'items-per-page-text': $t('listing.employers.pagination'),
          'items-per-page-options': itemsPerPageOptions
        }"
        data-cy="employersTable"
        multi-sort
        @update:options="handleTableOptionUpdated"
      >
        <template v-slot:item.actions="{ item }">
          <div class="py-2">
            <v-btn
              color="primary"
              class="ml-1"
              small
              @click="$emit('show-employer-details', item.id)"
            >
              {{ $t('listing.employers.details') }}
            </v-btn>
          </div>
        </template>

        <template v-slot:item.address="{ item }">
          {{ getFullAddress(item.address) }}
        </template>

        <template v-slot:item.created_at="{ value }">
          <span>{{ formatDate(value) }}</span>
        </template>
        <template v-slot:item.updated_at="{ value }">
          <span>{{ formatDate(value) }}</span>
        </template>

      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { filterManagement } from "@/services/filters"
import DataListTitle from '@/components/layout/DataListTitle.vue'
import DataTablesMixin from '@/mixins/dataTablesMixin'
import DatesMixin from '@/mixins/datesMixin'
import SearchFilterMixin from '@/mixins/searchFilterMixin'

export default {
  components: {DataListTitle },
  mixins: [DataTablesMixin, DatesMixin, SearchFilterMixin],
  props: {},
  data() {
    return {
      headers: [
        {
          text: this.$t('listing.employers.headers.name'),
          value: 'name',
          align: 'start'
        },
        {
          text: this.$t('listing.employers.headers.address'),
          value: 'address'
        },
        {
          text: this.$t('listing.employers.headers.created_at'),
          value: 'created_at',
        },
        {
          text: this.$t('listing.employers.headers.updated_at'),
          value: 'updated_at',
        },
        {
          value: 'actions',
          align: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapState('employers', ['storedSearch']),
  },
  methods: {
    ...mapActions('employers', ['getEmployers']),
    getFullAddress(address) {
      return `${address.address1}, ${address.zipCode} ${address.city}`
    },
    getDataFromApi () {
      this.loading = true

      const args = {
        ...filterManagement.getTableOptionsFromUrlQuery(this.$route),
        search: filterManagement.getSearchFromUrlQuery(this.$route),
      }

      args.sortBy = args.sortBy.map(attr => (attr === 'address') ? 'address__address_1' : attr)

      this.getEmployers(args).then(data => {
        this.items = data.items
        this.totalItems = data.count
        this.loading = false
      })
    },
  }
}
</script>
