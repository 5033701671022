/* eslint-disable no-param-reassign */
import { AgentFilterValues } from '@/helpers/agentFilter'
/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import router from '@/router'
import validItemsPerPage from '@/helpers/constants'
import _ from 'lodash'
import {QualificationFilterValues} from "@/helpers/qualificationFilter";

function getCurrentRouteCopy() {
  const currentRoute = router.history.current

  if (!Array.isArray(currentRoute.query.sortBy)) {
    currentRoute.query.sortBy = [currentRoute.query.sortBy]
  }

  if (!Array.isArray(currentRoute.query.sortDesc)) {
    currentRoute.query.sortDesc = [currentRoute.query.sortDesc]
  }

  if (!Array.isArray(currentRoute.query.candidates)) {
    currentRoute.query.candidates = [currentRoute.query.candidates]
  }

  if (!Array.isArray(currentRoute.query.employers)) {
    currentRoute.query.employers = [currentRoute.query.employers]
  }

  return { name: currentRoute.name, query: { ...currentRoute.query } }
}

function updateRoute(route) {
  router.push(route).catch(() => {})
}

function cleanNonBackendSortOptions(query) {
  delete query.multiSort
  delete query.mustSort
}

export const filterManagement = {
  setAgentFilterInUrlQuery(value) {
    const newRoute = getCurrentRouteCopy()

    if (newRoute.query.mine === value) {
      return
    }

    if (value === AgentFilterValues.Mine) {
      newRoute.query.mine = true
    } else {
      delete newRoute.query.mine
    }
    updateRoute(newRoute)
  },

  setQualificationFilterInUrlQuery(value) {
    const newRoute = getCurrentRouteCopy()

    if (newRoute.query.qualified === value) {
      return
    }

    if (value === QualificationFilterValues.Qualified) {
      newRoute.query.qualified = true
    } else {
      delete newRoute.query.qualified
    }
    updateRoute(newRoute)
  },

  setSearchFilterInUrlQuery(search) {
    const newRoute = getCurrentRouteCopy()

    if (newRoute.query.search === search) {
      return
    }

    if (search) {
      newRoute.query.search = encodeURIComponent(search)
    } else {
      delete newRoute.query.search
    }

    updateRoute(newRoute)
  },

  setTableOptionsInUrlQuery(options) {
    const newRoute = getCurrentRouteCopy()

    newRoute.query = { ...newRoute.query, ...options }
    cleanNonBackendSortOptions(newRoute.query)

    updateRoute(newRoute)
  },

  setCandidatesFilterInUrlQuery(candidatesIds) {
    const newRoute = getCurrentRouteCopy()

    if (_.isEqual(candidatesIds, newRoute.query.candidates))
      return

    if (candidatesIds) {
      newRoute.query.candidates = candidatesIds
    } else {
      delete newRoute.query.candidates
    }

    updateRoute(newRoute)
  },

  setEmployersFilterInUrlQuery(employersIds) {
    const newRoute = getCurrentRouteCopy()

    if (_.isEqual(employersIds, newRoute.query.employers))
      return

    if (employersIds) {
      newRoute.query.employers = employersIds
    } else {
      delete newRoute.query.employers
    }

    updateRoute(newRoute)
  },

  getSearchFromUrlQuery(route) {
    return route.query?.search ?? ''
  },

  getAgentFilterFromUrlQuery(route) {
    const mine = route.query?.mine
    return mine === true || mine === 'true'
      ? AgentFilterValues.Mine
      : AgentFilterValues.All
  },

  getQualificationFilterFromUrlQuery(route) {
    const qualified = route.query?.qualified
    return qualified === true || qualified === 'true'
      ? QualificationFilterValues.Qualified
      : QualificationFilterValues.All
  },

  getTableOptionsFromUrlQuery(route) {
    const options = {}
    if (route) {

      if (Array.isArray(route.query?.sortBy)) {
        options.sortBy = route.query.sortBy
      } else if (route.query?.sortBy) {
        options.sortBy = [route.query.sortBy]
      } else {
        delete options.sortBy
      }

      if (Array.isArray(route.query?.sortDesc)) {
        options.sortDesc = route.query.sortDesc.map(
          sort => sort === 'true' || sort === true
        )
      } else {
        const sort = route.query?.sortDesc
        options.sortDesc = [sort === 'true' || sort === true]
      }

      if (
        route.query?.itemsPerPage &&
        !Number.isNaN(route.query.itemsPerPage)
      ) {
        const itemsPerPage = Number.parseInt(route.query.itemsPerPage, 10)
        if (validItemsPerPage.includes(itemsPerPage))
          options.itemsPerPage = itemsPerPage
      }

      if (route.query?.page && !Number.isNaN(route.query.page)) {
        options.page = Number.parseInt(route.query.page, 10)
      }
    }
    return options
  },

  getCandidatesFilterFromUrlQuery(route) {
    if (Array.isArray(route.query?.candidates)) {
      return route.query.candidates
    }

    if (route.query?.candidates) {
      return [route.query.candidates]
    }

    return []
  },

  getEmployersFilterFromUrlQuery(route) {
    if (Array.isArray(route.query?.employers)) {
      return route.query.employers
    }

    if (route.query?.employers) {
      return [route.query.employers]
    }

    return []
  },

  resetPagination(options) {
    if (options) {
      options.page = 1
      filterManagement.setTableOptionsInUrlQuery(options)
    }
  }
}

export default filterManagement
